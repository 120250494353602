.data-type-handle:before {
    content: attr(data-symbol);
    user-select: none;
    font-weight: bold;
    font-size: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
