.Toastify__toast-container {
    width: unset;
    min-width: 250px;
    max-width: 600px;
}

.Toastify__toast {
    padding: 0;
    height: auto;
    background-color: transparent;
    border-radius: 4px;
    min-height: 48px;
    box-shadow: none;
    overflow: initial;
}

.Toastify__toast-body {
    padding: 0;
    margin: 0;
}

.Toastify__close-button {
    display: none;
}
