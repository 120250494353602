body {
    margin: 0;
}

* {
    font-family: Roboto, serif;
    box-sizing: border-box;
}

.node-stroke {
    stroke: #808080;
    stroke-width: 5px;
}

.selected-node-outline {
    stroke: #ff0000;
    stroke-width: 5px;
}

.react-flow__edge-path,
.react-flow__connection-path {
    stroke: #ff0000 !important;
    stroke-width: 5px !important;
}

.react-flow__handle-left {
    left: -66px !important;
}

.react-flow__handle-right {
    right: -66px !important;
}
